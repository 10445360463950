@import "../../variables";

.testimonial {
  background-color: $color3;

  * {
    color: $white;
  }

  .message-container {
    position: relative;

    .quote-start,
    .quote-end {
      font-size: 8rem;
      font-family: Arial;
      position: absolute;
      transform-origin: 50% 50%;
    }

    .quote-start {
      left: -0.5rem;
      top: 0rem;
    }

    .quote-end {
      transform: rotate(180deg);
      right: -0.5rem;
      bottom: 0rem;
    }
  }

  .message,
  .author {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .message {
    padding-top: 2rem;
    padding-bottom: 2rem;

    span {
      letter-spacing: 0;

      b {
        font-weight: 700;
        font-family: Poppins;
      }
    }
  }

  .author {
    text-align: right;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: Poppins, Arial;
    width: 100%;
    margin-top: 0;
    letter-spacing: 0;
  }

  .title {
    width: 100%;
    margin-top: 3rem;
    text-align: center;

    .inner-container {
      background-color: $color1;
      font-family: Poppins, Arial;
      display: inline-block;
    }
  }

  @media (max-width: $xs) {
    .author {
      font-size: 1.2rem;
      padding: 0 2rem;
      text-align: left;
      margin-top: 1rem;
    }
    .title {
      font-size: 1.5rem;
      letter-spacing: 0;
    }
    .message-container {
      .message {
        padding-top: 0;
        padding: 0 2rem;
      }
      .quote-start,
      .quote-end {
        font-size: 6rem;
      }
    }
  }
}
