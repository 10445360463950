@import "../../variables";

.cover-image-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 7rem 1rem;
  width: 100%;
  background-size: cover;

  .title,
  .subtitle {
    text-align: center;
    color: $white;
    width: 100%;
    text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.5);
    font-size: 3rem;
    letter-spacing: -1px;
    font-weight: 600;
  }

  @media (max-width: $md) {
    padding: 3.5rem 0.5rem;
    margin-bottom: 1rem;

    .title,
    .subtitle {
      font-size: 2.5rem;
      letter-spacing: -1px;
    }
  }
  @media (max-width: $sm) {
    padding: 2rem 0.5rem;
    margin-bottom: 1rem;

    .title,
    .subtitle {
      font-size: 1.5rem;
      letter-spacing: -1px;
    }
  }
}
