@import "../../variables";

.team-member {
  margin-bottom: 4rem;
  margin-top: 4rem;

  &:first-of-type {
    margin-top: 0;
  }

  .name {
    letter-spacing: -2px;

    @media (max-width: $sm) {
      font-size: 1.7rem;
      text-align: center;
    }
  }

  .title {
    margin-top: -0.7rem;
    letter-spacing: 0px;

    @media (max-width: $sm) {
      text-align: center;
      margin-top: 0;
    }
  }

  .portrait-container {
    text-align: center;
    img {
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;

      @media (max-width: $sm) {
        max-width: 25rem;
        width: 100%;
        padding: 2rem;
      }
    }
  }

  @media (max-width: $sm) {
    .portrait-column {
      order: -1;
    }
  }
}
