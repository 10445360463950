* {
  font-family: Karla, Arial;
  color: $black;
}

h1,
h2 {
  font-family: Poppins, Arial;
}

h1 {
  font-size: 3.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -3px;

  @media (max-width: $sm) {
    font-size: 2.5rem;
  }

  @media (max-width: $xs) {
    font-size: 2rem;
    letter-spacing: 0;
  }
}

h2 {
  font-size: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
  letter-spacing: 0px;

  @media (max-width: $md) {
    font-size: 2rem;
    letter-spacing: -1px;
  }
}

h3 {
  font-size: 1.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-family: Poppins, Arial;
  margin: 0.6rem 0;
  letter-spacing: -2px;
  line-height: 1.2;

  @media (max-width: $sm) {
    font-size: 1.5rem;
    letter-spacing: 0px;
    line-height: 1;
  }
}

h4 {
  font-size: 1.4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  letter-spacing: -1px;

  @media (max-width: $md) {
    font-size: 1.2rem;
    letter-spacing: -1px;
    line-height: 1.5;
  }
}

h5 {
  text-transform: uppercase;
  color: $darkGray;
  font-size: 0.9rem;
  // font-weight: bold;
  font-weight: 600;
  letter-spacing: 2px;
  font-family: Poppins, Arial;
  margin: 0.2rem 0;
}

p {
  font-size: 1.2rem;
  color: $darkGray;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 1.6;

  @media (max-width: $sm) {
    font-size: 1.05rem;
  }
}

a {
  text-decoration: none;
}

b {
  &.highlight {
    color: $color3;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}
