@import "../../variables";

.footer-widget {
  margin-bottom: 1rem;

  @media (max-width: $md) {
    margin-bottom: 0;
  }

  .title {
    font-weight: 700;
    font-family: Poppins, Arial;
    letter-spacing: 2px;
    color: $white;
    font-size: 0.85rem;
    text-transform: uppercase;
  }

  p {
    color: $white;
    opacity: 0.6;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
  }
}
