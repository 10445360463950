@import "../../variables";

.list {
  list-style-type: none;
  padding-left: 0;
  // margin: 0;
  margin-bottom: 0;
  margin-top: 0;

  li {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 1rem;
      path {
        fill: $color3;
      }
    }

    .order {
      margin-right: 1rem;
      font-size: 1.3rem;
      font-weight: 700;
      font-family: Poppins, Arial;
      color: $color3;
    }

    p.label {
      margin: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
