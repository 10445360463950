$color1: #1b2032;
$color2: #ed6673;
$color3: #0079ea;
$color4: #edb936;

$white: #fff;
$black: #272d3a;
$gray: #eee;
$darkGray: rgb(92, 103, 112);

$discountColor: darken($color2, 10);

$max-page-width: 1280px;
$logo-desktop-width: 300px;
$logo-mobile-width: 230px;

$lg: 1200px;
$md: 990px;
$sm: 767px;
$xs: 450px;
