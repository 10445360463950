@import "../variables";

.privacy-policy {
  margin-bottom: 3rem;

  h4 {
    margin: 0.5rem 0;
    margin-top: 2rem;
  }
  ul {
    margin-top: 0;
    li {
      font-size: 1rem;
      letter-spacing: -1px;
      color: $darkGray;
      line-height: 1.5;
    }
  }
  p {
    margin: 0.5rem 0;
    font-size: 1rem;
  }
}
