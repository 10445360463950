@import "../../variables";

.service-item {
  margin-top: 30px;
  display: block;
  position: relative;
  padding: 1.5rem;
  position: relative;
  background-color: transparent;
  background-color: lighten($color3, 5);

  box-shadow: 10px 10px 30px darken($color3, 7),
    -10px -10px 30px lighten($color3, 7);
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  min-height: 15.5rem;

  @media (max-width: $sm) {
    min-height: unset;
  }

  .icon-container {
    text-align: right;
    position: absolute;
    color: $white;
    right: 10px;
    top: 10px;
    background-color: darken($color3, 2);
    height: 3rem;
    width: 3rem;
    text-align: center;
    line-height: 3rem;
    border-radius: 50%;

    .icon {
      transform: scale(1.2);
      path {
        fill: $white;
      }
    }
  }
  .learn-more {
    margin-top: auto;
    margin-bottom: 0;
    color: $white;
    text-align: center;
    font-weight: 700;

    .icon {
      path {
        fill: $white;
      }
    }
  }

  .name {
    font-family: Poppins, Arial;
    letter-spacing: -1px;
    width: 18rem;
    color: $white;
    margin: 0;
    margin-top: -0.5rem;

    @media (max-width: $lg) {
      width: 12rem;
    }

    @media (max-width: $md) {
      font-size: 1.4rem;
      letter-spacing: -1px;
      width: 15rem;
    }

    @media (max-width: $sm) {
      font-size: 1.3rem;
      letter-spacing: -1px;
      width: 10.5rem;
    }
  }

  p.description {
    color: $white;
    letter-spacing: 0;
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: -1px;
  }

  a {
    color: $white;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: Poppins, Arial;
    text-align: center;
    width: 100%;
    display: inline-block;
    position: absolute;
    bottom: 1rem;
    left: 0;
  }

  .svg-inline--fa {
    position: relative;
    top: 2px;
    transform: scale(0.9);
  }

  .button-icon-right {
    margin-left: 10px;
  }

  @media (hover: hover) {
    &:hover {
      $colorHover: $color4;
      background-color: $colorHover;
      border-color: $colorHover;

      .description {
        opacity: 1;
      }
      .icon-container {
        background-color: darken($colorHover, 10);
      }
    }
  }
}
