@import "../../variables";

.clients {
  padding-top: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  width: 100%;

  .title {
    width: 100%;
  }

  .clients-grid {
    margin-top: 3rem;
    width: 100%;

    .column {
      margin-bottom: 5rem;
      display: flex;
      justify-content: center;

      .logo-sky {
        position: relative;
        top: 7px;
      }
      .logo-holland-barrett {
        position: relative;
        top: 5px;
      }
    }
  }
}
