@import "../../variables";

.newsletter-form {
  width: 100%;
  max-width: 600px;
  margin-bottom: 50px;

  .title {
    color: $white;
  }

  input {
    padding: 10px 20px;
    font-size: 0.9rem;
    border: 0;
    border-radius: 5px;
    width: 100%;
    outline: 0;
    border: 2px solid $white;

    &::placeholder {
      color: $color1;
    }

    &:focus {
      border: 2px solid $color2;

      &::placeholder {
        color: saturate(darken($color2, 30), 30);
      }
    }
  }
}
