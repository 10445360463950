@import "../../variables";

.case-study-hiit-tribe {
  @media (min-width: $md) {
    .highlights {
      h3 {
        text-align: center;
        margin-bottom: 2rem;
      }
    }
  }
}
