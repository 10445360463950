@import "../../variables";

.page-title-container {
  max-width: 50rem;
  margin: 4rem auto;

  .title {
    text-align: center;
    font-size: 2rem;
    letter-spacing: 0;
    font-weight: 700;
  }

  .tagline {
    text-align: center;
    color: $darkGray;
  }

  @media (max-width: $md) {
    margin: 2rem auto;
  }

  &.light {
    .title {
      color: $white;
    }
  }
}
