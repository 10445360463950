@import "../../variables";

button {
  padding: 10px 20px;
  border-radius: 3px;
  font-weight: 700;
  font-family: Poppins, Arial;

  margin-top: 20px;
  display: block;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  transition: all 400ms;
  transform: scale(1);
  user-select: none;
  outline: unset;

  @keyframes scale {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }

  @mixin focus-state($buttonColor) {
    color: darken($buttonColor, 40);

    &::before {
      content: "";
      position: absolute;
      bottom: -7px;
      left: -2px;
      width: calc(100% + 4px);
      height: 2px;
      border-bottom: 2px solid $buttonColor;

      animation-name: scale;
      animation-duration: 400ms;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;

      transform: scale(0);
    }
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(0.9);
    }
  }

  &.inline {
    margin-top: 0;
    display: inline-block;
  }

  &.primary {
    border: 2px solid $color3;
    background-color: $color3;
    color: $white;
    position: relative;

    &:focus {
      @include focus-state($color3);
    }
  }

  &.secondary {
    background-color: $color2;
    border: 2px solid $color2;
    color: $white;

    &:focus {
      @include focus-state($color2);
    }
  }

  &.linkedin {
    font-family: Poppins, Arial;
    font-weight: 600;
    background-color: #0e76a8;
    border-color: #0e76a8;
    color: $white;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    padding: 0;
    font-weight: bold;
    font-size: 1.2rem;
  }

  &.disabled {
    background-color: $gray;
    border: 1px solid $gray;
    color: $color1;
    pointer-events: none;
  }
}
