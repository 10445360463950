@import "./variables";
@import "./typography";

* {
  box-sizing: border-box;
}

::selection {
  background-color: $color1;
  color: white;
}

img {
  display: inline-block;
  max-width: 100%;
  user-select: none;

  &.full-width {
    width: 100%;
  }
}
