@import "../variables";

.page-home {
  .hero {
    .tagline {
      font-size: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 3rem;
      font-weight: 900;
      letter-spacing: -1px;
      color: lighten($color3, 15);
      line-height: 1.2;
    }
  }

  .segment {
    padding: 10rem 2rem;

    @media (max-width: $lg) {
      padding: 3rem 0rem;
    }
    @media (max-width: $md) {
      padding: 3rem 0rem;
    }

    &.hero {
      padding-top: 6rem;
      padding-bottom: 11rem;

      background-color: $color1;
      color: $white;

      @media (max-width: $md) {
        padding: 2rem 0rem 3rem;
      }

      h1 {
        color: $white;
        margin-bottom: 3rem;
        margin-top: 0;
        line-height: 1.1;

        @media (max-width: $md) {
          margin-bottom: 1.5rem;
          font-size: 2.5rem;
        }
      }

      .tagline {
        @media (max-width: $md) {
          font-size: 1.2rem;
          margin-bottom: 2.5rem;
          line-height: 1.5;
          font-weight: 700;
        }
      }

      button {
        padding: 0.7rem 2.1rem;
        font-size: 1.1rem;
        display: inline-block; // otherwise, the hit area for the link around it expands to the entire width of the column
      }

      .illustration-container {
        text-align: right;
      }
    }

    &.purple {
      background-color: $color3;
      color: $white;
      padding-top: 6rem;
      padding-bottom: 6rem;

      @media (max-width: $md) {
        padding: 2rem 0;
      }
    }
  }
}
