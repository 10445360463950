@import "../variables";

.contact-form-container {
  background-color: $color3;
  padding-top: 2rem;
  padding-bottom: 12rem;

  h1,
  h4,
  p {
    transition: font-size 400ms;
  }

  @media (max-width: 600px) {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .inner-container {
    display: flex;
    justify-content: center;
    width: 100%;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  h1 {
    display: inline-block;
    font-size: 2.5rem;
    margin: 0;
    letter-spacing: -1px;

    .line {
      display: block;
      font-family: Poppins, Arial;

      &:not(:first-child) {
        margin-top: -0.5rem;
      }

      @media (max-width: 600px) {
        display: inline-block;
        margin-bottom: 1rem;

        &:not(:first-child) {
          margin-left: 0.5rem;
        }
      }
    }

    @media (max-width: $md) {
      font-size: 2rem;
    }
    @media (max-width: $sm) {
      font-size: 1.7rem;
    }

    p {
      @media (max-width: $sm) {
        font-size: 1rem;
      }
    }
  }

  .form {
    border-radius: 0.6rem;
    width: 60%;
    position: relative;
    padding: 0 3vw;
    padding-bottom: 4vw;

    @media (max-width: $md) {
      width: 50%;
    }

    @media (max-width: 600px) {
      background-color: $white;
      width: 100%;
      padding: 1.5rem;
    }

    .form-content {
      border-radius: 0.6rem;
      position: relative;
      z-index: 2;
      padding-top: 2vw;

      .form-title-container {
        margin-bottom: 2vw;
      }

      @media (max-width: 600px) {
        padding-top: 0;
      }
    }

    &::before {
      content: "";
      border-radius: 10px;
      position: absolute;
      top: 0px;
      left: 0;
      background-color: #fff;
      width: 130%;
      height: 100%;
      z-index: 1;

      @media (max-width: 600px) {
        display: none;
      }
    }

    .input-group {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      input,
      textarea {
        border: none;
        border: 2px solid $gray;
        border-radius: 5px;
        font-size: 16px;
        padding-left: 10px;
        width: 100%;
        height: 2rem;
        outline: unset;
        -webkit-appearance: none;
        font-weight: 700;

        &::placeholder {
          font-size: 0.9rem;
          line-height: 1.5rem;
          font-weight: 400;
        }

        &:focus {
          border: 2px solid $color2;

          &::placeholder {
            color: darken($color2, 30);
          }
        }
      }

      textarea {
        height: 6.2rem;
        padding-top: 0.6rem;
        padding-right: 1rem;
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 600px) {
        justify-content: center;
      }
    }
  }
  .contact-details-container {
    padding: 2vw;
    width: 40%;
    position: relative;
    top: 6rem;

    @media (max-width: $md) {
      width: 50%;
    }

    @media (max-width: 600px) {
      width: 100%;
      padding: 0;
      top: 0;
      margin-top: 1rem;
    }

    a {
      font-size: 1.5rem;
    }

    .subtitle {
      border-left: 3px solid $white;
      padding-left: 1rem;
    }

    .location-info {
      margin-top: 2rem;
      margin-bottom: 2rem;

      .row {
        display: flex;
        align-items: flex-start;

        .icon-column {
          width: 1.5rem;
          margin-right: 2rem;
          padding-top: 0.5rem;
          text-align: center;
          padding-top: 0.5rem;
        }

        .info-column {
          width: 100%;

          h4,
          p {
            margin: 0;
          }
        }
      }
      .icon {
        path {
          fill: $color2;
        }
      }
    }

    .social-icons-container {
      padding-left: 2.3rem;

      a {
        padding: 1rem;
        transition: all 400ms;
        display: inline-block;

        &:hover {
          transform: scale(0.8);
        }

        .icon {
          path {
            fill: $white;
          }
        }
      }

      @media (max-width: 600px) {
        display: flex;
        justify-content: flex-start;
      }
    }

    .contact-details-content {
      border-radius: 10px;
      position: relative;
      z-index: 4;

      @media (max-width: 600px) {
        background-color: $color1;
        padding: 1.5rem;
      }
    }

    &::before {
      content: "";
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: -5px;
      background-color: $color1;
      width: 100%;
      height: 100%;
      z-index: 3;

      @media (max-width: 600px) {
        display: none;
      }
    }

    h1,
    h4,
    p {
      color: $white;
    }
  }

  .form-header {
    margin: 0;
  }

  .full-stop {
    display: inline-block;
    background-color: $color2;
    margin-left: 0.9rem;
    width: 0.6rem;
    height: 0.6rem;
  }
}
