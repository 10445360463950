@import "../../variables";

hr {
  border: 0;
  height: 1px;
  background-color: $gray;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}
