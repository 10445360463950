@import "../../variables";

.thumbnail-container {
  margin-top: 0rem;
  margin-bottom: 2rem;
  padding: 7rem 1rem;
  width: 100%;
  cursor: pointer;
  position: relative;
  display: block;

  .background {
    position: absolute;
    background-size: cover;
    z-index: -1;
    border-radius: 1rem;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: saturate(0.6) contrast(0.9) brightness(0.7);
    transition: all 300ms;
  }

  .title,
  .subtitle {
    text-align: center;
    color: $white;
    width: 100%;
    text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.5);
  }

  .title {
    letter-spacing: 1px;
  }

  .subtitle {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0;
  }

  @media (max-width: $md) {
    padding: 5rem 0.5rem;
    margin-bottom: 1rem;

    .title {
      font-size: 3.5rem;
      letter-spacing: -1px;
    }

    .subtitle {
      font-size: 1.8rem;
      letter-spacing: -1px;
    }
  }

  @media (max-width: $sm) {
    padding: 3rem 0.5rem;
    margin-bottom: 1rem;

    .title {
      font-size: 2.5rem;
      letter-spacing: -1px;
    }

    .subtitle {
      font-size: 1.2rem;
      letter-spacing: -1px;
    }
  }

  @media (max-width: $xs) {
    padding: 1.8rem 0.5rem;
    .subtitle {
      font-size: 1.1rem;
    }
  }

  @media (hover: hover) {
    &:hover {
      .background {
        filter: saturate(1.2) contrast(1.2) brightness(0.9);
      }
    }
  }

  @media (hover: none) {
    .background {
      filter: saturate(1) contrast(1) brightness(0.8);
    }
  }
}
