@import "../variables";

.service-page {
  .see-prices-button {
    margin-bottom: 1.5rem;
  }

  .service-info {
    padding-bottom: 3rem;

    .service-name {
      margin-top: 0;
    }

    p:first-child {
      margin-top: 0;
    }
  }
}
