@import "../../variables";

.boxed-layout {
  max-width: $max-page-width;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: $sm) {
    padding: 0 1rem;
  }
}
